import styles from './index.module.css'
import { useCallback, useMemo, useState } from 'react'
import RegistrationsQuickLogin from '../RegistrationsQuickLogin'
import RegistrationsPasswordLogin from '../RegistrationsPasswordLogin'
import RegistrationsCoupons from '../RegistrationsCoupons'
import RegistrationsRemainder from '../RegistrationsRemainder'
import RegistrationsActivate from '../RegistrationsActivate'
import RegistrationsSubscribeSMS from '../RegistrationsSubscribeSMS'
import { RegistrationsStatus } from '../../const'
import registrationsTrack from '../../RegistrationsTrack'
import { useUI } from '@components/ui/context'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { validate } from 'email-validator'
import fetcher from '@lib/fetcher'
import { getAppName } from '../../const'
import { getAdCookie } from '@lib/utils/cookies'
import Cookies from 'js-cookie'
import {
  I18N_STORE_DOMAIN,
  MULPASS_URL,
  SHORT_LOCALE_MAP,
} from '@shopify/const'
import { useGlobal } from '@components/common/GlobalContext'
import { tsHtml2LocaleLink } from '@lib/utils/tools'
import { features } from 'commerce.config'

const defaultSubscribeCountry = ['us', 'ca']

/**
 * Renders the RegistrationsComponent.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data object.
 * @param {Function} props.onClose - The function to close the component.
 * @param {Object} props.gs - The gs object.
 * @param {string} props.currentStatus - The current status.
 * @param {Function} props.setCurrentStatus - The function to set the current status.
 * @param {string} props.loadingColor - The loading color.
 * @returns {JSX.Element} The rendered RegistrationsComponent.
 */
const RegistrationsComponent = ({
  data,
  onClose,
  gs,
  currentStatus,
  setCurrentStatus,
  loadingColor = '#ffffff',
}) => {
  const {
    registrationsModelStatus,
    registrationsDealsType,
    setActiveToken,
    registrationsOptions,
  } = useUI()
  const [registrationsStatus, setRegistrationsStatus] = useState(
    registrationsModelStatus || RegistrationsStatus.MAIN
  )
  const [coupons, setCoupons] = useState([])
  const [registrationsFillEmail, setRegistrationsFillEmail] = useState('')
  const [fillEmail, setFillEmail] = useState('')
  const [rsqlFillEmail, setRsqlFillEmail] = useState('')
  const [rsrFillEmail, setRsrFillEmail] = useState('')
  const [backType, setBackType] = useState('')
  const s = { ...styles }

  const [submitLoading, setSubmitLoading] = useState(false)
  const [canAutoSignup, setCanAutoSignup] = useState(true)
  const [serviceError, setServiceError] = useState(false)
  const [formData, setFormData] = useState(true)
  const [sendLoading, setSendLoading] = useState(false)

  const { registrationsMain } = data
  const { setLoginLoading } = useGlobal()

  const { locale } = useRouter()
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: { registrations: { email: registrationsFillEmail } },
  })

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const handleSendVerifyEmail = useCallback(async () => {
    if (sendLoading) return
    setSendLoading(true)
    const res = await fetcher({
      action: 'user',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: formData?.registrations,
        app,
      },
    }).catch((e) => {
      console.log(e, 'e')
    })
    if (res.status > 100 && res.status < 300) {
      // setBackType('main')
      setRegistrationsStatus(RegistrationsStatus.ACTIVATE)
      setCurrentStatus(RegistrationsStatus.ACTIVATE)
    }
    setSendLoading(false)
  }, [app, formData?.registrations, locale, sendLoading, setCurrentStatus])

  // eslint-disable-next-line no-unused-vars
  const handleQuickLogin = useCallback(
    (email) => {
      setFillEmail(email)
      setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
      setCurrentStatus(RegistrationsStatus.QUICK_LOGIN)
    },
    [setCurrentStatus]
  )

  const handleCreateAccount = useCallback(
    (email) => {
      setRegistrationsStatus(RegistrationsStatus.MAIN)
      setCurrentStatus(RegistrationsStatus.MAIN)
      setRegistrationsFillEmail(email)
    },
    [setCurrentStatus]
  )

  const handleQuickLoginSuccess = useCallback(
    (email) => {
      setRsrFillEmail(email)
      setRegistrationsStatus(RegistrationsStatus.REMAINDER)
      setCurrentStatus(RegistrationsStatus.REMAINDER)
    },
    [setCurrentStatus]
  )

  const handlePasswordLogin = useCallback(
    (email) => {
      setRsqlFillEmail(email)
      setRegistrationsStatus(RegistrationsStatus.PASSWORD_LOGIN)
      setCurrentStatus(RegistrationsStatus.PASSWORD_LOGIN)
    },
    [setCurrentStatus]
  )

  const onRegistrationsSuccess = useCallback(
    (res, email) => {
      const { coupons } = res.data
      setCoupons(coupons)
      setFillEmail(email)
      if (registrationsOptions?.memberRegistration) {
        setRegistrationsStatus(RegistrationsStatus.AUTH_CODE)
        setCurrentStatus(RegistrationsStatus.AUTH_CODE)
        return
      }
      setRegistrationsStatus(
        locale === 'us'
          ? RegistrationsStatus.SUBSCRIBE_SMS
          : RegistrationsStatus.COUPONS
      )
      setCurrentStatus(
        locale === 'us'
          ? RegistrationsStatus.SUBSCRIBE_SMS
          : RegistrationsStatus.COUPONS
      )
    },
    [locale, registrationsOptions?.memberRegistration, setCurrentStatus]
  )

  const onRegistrationsError = useCallback(
    (res, email) => {
      setFillEmail(email)
      // 注册失败
      if (res.status === 422) {
        // 已注册
        setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
        setCurrentStatus(RegistrationsStatus.QUICK_LOGIN)
      }
    },
    [setCurrentStatus]
  )

  const onRegistrationsVerify = (email) => {
    setFillEmail(email)
  }

  const handleFocus = useCallback(() => {
    // start_ai_generated
    clearErrors('registrations[email]')
    setServiceError(false)
    // end_ai_generated
  }, [clearErrors])

  const getDomain = useCallback(() => {
    const hostUrl = window.location.host.split('.')
    return location.href.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`
  }, [])

  const setCookie = useCallback(
    ({ auth_token }) => {
      const domain = getDomain()
      Cookies.set(`${app}-token`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`${app}-info`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`alpcEU`, features[locale]?.isEuLocale ? '1' : '0', {
        domain,
        expires: 30,
      })
    },
    [app, getDomain, locale]
  )

  const isDefaultSubscribeCountry = useMemo(
    () => defaultSubscribeCountry.includes(locale),
    [locale]
  )

  const country_code = useMemo(() => {
    const shortLocale = SHORT_LOCALE_MAP[locale || ''] || locale
    return (shortLocale || data?.globalCountry?.code).toUpperCase()
  }, [locale, data?.globalCountry?.code])

  const onSubmit = useCallback(
    async (formData) => {
      setSubmitLoading(true)
      setFormData(formData)
      const { email, subscribe } = formData?.registrations
      const { register_source } = getAdCookie()
      setLoginLoading(true)
      const res = await fetcher({
        action: 'login',
        needRecaptcha: true,
        locale,
        url: '/api/multipass/passport-api/auto-signup',
        method: 'POST',
        body: {
          email,
          app,
          country_code,
          register_source,
          data: {
            redirect_url: `${window.location.origin}/${locale}?ref=edm_member_activation`,
          },
        },
        headers: {
          'User-Agent': navigator?.userAgent,
        },
      }).catch((e) => {
        console.log(e, 'e')
        setSubmitLoading(false)
      })
      const domain = getDomain()

      if (res.status > 100 && res.status < 300) {
        const { auth_token, canAutoSignup, multipassinfo, active_token } =
          res?.data?.data
        registrationsTrack.registrationsTrack({
          action: 'success',
          subscribe: subscribe ? 1 : 0,
          email,
          deals_type: registrationsMain?.dealsType,
        })
        const b_auth_token = btoa(auth_token)
        setCookie({ auth_token, multipassinfo })
        setCanAutoSignup(canAutoSignup)
        if (canAutoSignup) {
          setActiveToken(active_token)
          localStorage.setItem('b_auth_token', b_auth_token)
          Cookies.set(`multipassinfo`, multipassinfo, {
            domain,
            expires: 30,
          })
          const passportLoginUrl = `${MULPASS_URL}/login?app=${app}&t=${b_auth_token}&redirect=${encodeURIComponent(
            location.href
          )}`
          Cookies.set('passport-login-url', passportLoginUrl, {
            domain,
            expires: 30,
          })
          // 注册成功
          let url = '/api/multipass/rainbowbridge/activities'
          const result = await fetcher({
            action: 'activity',
            needRecaptcha: true,
            locale,
            url,
            method: 'POST',
            headers: {
              'X-Application-Name': `${app}-shopify`,
              'x-application-token': res?.data?.data?.auth_token,
            },
            body: {
              email,
              register_source,
              shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
              single_brand_subscribe: true,
              genre: registrationsDealsType || registrationsMain?.dealsType,
              promise_subscribe: subscribe,
            },
          }).finally(() => setSubmitLoading(false))
          if (!result.errors) {
            // 订阅成功
            onRegistrationsSuccess && onRegistrationsSuccess(result, email)
          }
        } else {
          onRegistrationsVerify && onRegistrationsVerify(email)
          setSubmitLoading(false)
        }
      } else {
        setSubmitLoading(false)
        setServiceError(true)
        // 注册失败
        onRegistrationsError && onRegistrationsError(res, email)
        if (res.status === 422) {
          setServiceError(false)
        }
      }
      setLoginLoading(false)
    },
    [
      setLoginLoading,
      locale,
      app,
      country_code,
      getDomain,
      registrationsMain?.dealsType,
      setCookie,
      setActiveToken,
      registrationsDealsType,
      onRegistrationsSuccess,
      onRegistrationsError,
    ]
  )

  return (
    <>
      <div
        className={cn(gs.rsm_close_mask, '!fixed', {
          hidden:
            registrationsStatus === RegistrationsStatus.MAIN ||
            currentStatus == RegistrationsStatus.MAIN,
        })}
        aria-label="mask"
      ></div>
      <div
        className={cn(s.registrations_main, { [s.disabled]: submitLoading })}
      >
        <h3
          className="text-center text-2xl font-bold leading-[1.2] text-[#333] min-xl:text-4xl"
          dangerouslySetInnerHTML={{ __html: registrationsMain?.title }}
        ></h3>
        <p
          className="mt-3 text-center text-base font-medium leading-[1.4] text-[#333]"
          dangerouslySetInnerHTML={{ __html: registrationsMain?.subtitle }}
        ></p>
        <div className="mt-[14px] min-md:mt-6 min-l:mt-8">
          <form
            method="post"
            className={s.rsm_form}
            acceptCharset="UTF-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={s.input_box}>
              <input
                className={cn(s.rsm_input, s.registrations_input_main, {
                  [s.input_error]: errors?.registrations?.email,
                })}
                name="registrations[email]"
                onFocus={handleFocus}
                maxLength="200"
                aria-label={registrationsMain?.placeholder}
                placeholder={registrationsMain?.placeholder}
                {...register('registrations[email]', {
                  required: registrationsMain?.info_fill_email,
                  validate: (value) =>
                    validate(value) || registrationsMain?.info_err_email,
                })}
              />
              <p className={cn(s.form_error, 'text-center')}>
                {errors?.registrations?.email?.message}
              </p>
              {submitLoading ? (
                <button
                  className={cn(
                    s.rsm_submit,
                    s.registrations_btn_main,
                    s.flex_center,
                    s.loading_btn
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                      stroke={loadingColor}
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  type="submit"
                  className={cn(
                    s.rsm_submit,
                    s.registrations_btn_main,
                    s.flex_center
                  )}
                >
                  {registrationsMain?.register_text}
                </button>
              )}
            </div>
            <p className={cn(s.form_error, 'hidden text-center min-md:block')}>
              {errors?.registrations?.email?.message}
            </p>
            <p className={cn(s.rsm_policy_box, s.checkout_box)}>
              <input
                className={s.rsm_checkout}
                name="registrations[policy]"
                type="checkbox"
                defaultChecked={isDefaultSubscribeCountry}
                aria-label="policy-checkbox"
                {...register('registrations[policy]', {
                  required: registrationsMain?.info_select_policy,
                })}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: tsHtml2LocaleLink({
                    html: registrationsMain?.policy_text,
                    locale,
                  }),
                }}
                className={s.rsm_checkout_text}
              ></span>
            </p>
            <p className={s.form_error}>
              {errors?.registrations?.policy?.message}
            </p>
            <p className={cn(s.rsm_subscribe_box, s.checkout_box)}>
              <input
                className={s.rsm_checkout}
                name="registrations[subscribe]"
                type="checkbox"
                defaultChecked={isDefaultSubscribeCountry}
                aria-label="subscribe-checkbox"
                {...register('registrations[subscribe]')}
              />
              <span className={s.rsm_checkout_text}>
                {registrationsMain.subscribe_text}
              </span>
            </p>
            <p className={s.form_error}>
              {errors?.registrations?.subscribe?.info_select_policy}
            </p>
            {!canAutoSignup && (
              <p
                className={cn(
                  s.form_error,
                  'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
                )}
              >
                <span>{registrationsMain?.verifyError}</span>&nbsp;
                <a
                  onClick={handleSendVerifyEmail}
                  className=" cursor-pointer text-[#005D8E] underline"
                >
                  {registrationsMain?.sendEmailText}
                </a>
              </p>
            )}
            {serviceError && (
              <p
                className={cn(
                  s.form_error,
                  'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
                )}
              >
                <span>{registrationsMain?.serviceError}</span>
              </p>
            )}
          </form>
          {/* <p className={s.rsm_login}>
            {registrationsMain.login_text}
            <a
              onClick={() =>
                onQuickLogin && onQuickLogin(getValues('registrations[email]'))
              }
            >
              {registrationsMain?.login_now_text}
            </a>
          </p> */}
        </div>
      </div>
      {/* <RegistrationsMain
        data={data?.registrationsMain}
        gs={s}
        // 验证邮箱发送成功
        onSendVerifyEmailSuccess={() => {
          setBackType('main')
          setRegistrationsStatus(RegistrationsStatus.ACTIVATE)
          setCurrentStatus(RegistrationsStatus.ACTIVATE)
        }}
        registrationsFillEmail={registrationsFillEmail}
        onQuickLogin={handleQuickLogin}
        onRegistrationsSuccess={handleRegistrationsSuccess}
        onRegistrationsError={handleRegistrationsError}
        //
        onRegistrationsVerify={(email) => {
          setFillEmail(email)
        }}
      /> */}
      <modal
        className={cn(s.registrations_model, {
          hidden:
            registrationsStatus === RegistrationsStatus.MAIN ||
            currentStatus == RegistrationsStatus.MAIN,
        })}
        aria-labelledby="registrations-model"
        aria-describedby="registrations-model"
      >
        <div className={s.registrations_model_wrap}>
          <button className={s.rsm_close}>
            <CloseIcon
              onClose={() => {
                setRegistrationsStatus(RegistrationsStatus.MAIN)
                setCurrentStatus(RegistrationsStatus.MAIN)
                onClose(registrationsStatus)
              }}
            />
          </button>
          {registrationsStatus === RegistrationsStatus.QUICK_LOGIN && (
            <RegistrationsQuickLogin
              fillEmail={fillEmail}
              data={data?.registrationsQuickLogin}
              gs={s}
              onPasswordLogin={handlePasswordLogin}
              onQuickLoginSuccess={handleQuickLoginSuccess}
              onCreateAccount={handleCreateAccount}
              onQuickLoginBack={() => {
                registrationsTrack.backRegistrationsTrack({
                  buttonName: 'back',
                })
                setRegistrationsStatus(RegistrationsStatus.MAIN)
                setCurrentStatus(RegistrationsStatus.MAIN)
                setRegistrationsFillEmail(fillEmail)
              }}
            />
          )}
          {registrationsStatus === RegistrationsStatus.PASSWORD_LOGIN && (
            <RegistrationsPasswordLogin
              rsqlFillEmail={rsqlFillEmail}
              setFillEmail={setFillEmail}
              onPasswordLoginSuccess={() => onClose()}
              data={data?.registrationsPasswordLogin}
              gs={s}
              onPasswordLoginBack={() => {
                setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
                setCurrentStatus(RegistrationsStatus.QUICK_LOGIN)
              }}
              onSendVerifyEmailSuccess={(email) => {
                setFillEmail(email)
                setBackType('passwordLogin')
                setRegistrationsStatus(RegistrationsStatus.ACTIVATE)
                setCurrentStatus(RegistrationsStatus.ACTIVATE)
              }}
            />
          )}
          {registrationsStatus === RegistrationsStatus.COUPONS && (
            <RegistrationsCoupons
              data={data?.registrationsCoupons}
              gs={s}
              registerEmail={fillEmail}
              coupons={coupons}
              onCouponsGet={() => {
                setRegistrationsStatus(RegistrationsStatus.MAIN)
                setCurrentStatus(RegistrationsStatus.MAIN)
                onClose(registrationsStatus)
              }}
              onCouponsBack={() => {
                setRegistrationsStatus(RegistrationsStatus.MAIN)
                setCurrentStatus(RegistrationsStatus.MAIN)
              }}
            />
          )}
          {registrationsStatus === RegistrationsStatus.REMAINDER && (
            <RegistrationsRemainder
              data={data?.registrationsRemainder}
              gs={s}
              rsrFillEmail={rsrFillEmail}
              onReminderBack={() => {
                setFillEmail(rsrFillEmail)
                setRegistrationsFillEmail('')
                setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
                setCurrentStatus(RegistrationsStatus.QUICK_LOGIN)
              }}
              onReminderGet={() => onClose()}
            />
          )}
          {registrationsStatus === RegistrationsStatus.SUBSCRIBE_SMS && (
            <RegistrationsSubscribeSMS
              data={data?.registrationsSubscribeSMS}
              gs={s}
              subscribe={formData?.registrations?.subscribe}
              registerEmail={fillEmail}
              onSubscribeSMSSkip={() => {
                setRegistrationsStatus(RegistrationsStatus.COUPONS)
                setCurrentStatus(RegistrationsStatus.COUPONS)
              }}
              onSubscribeSMSSuccess={() => {
                setRegistrationsStatus(RegistrationsStatus.COUPONS)
                setCurrentStatus(RegistrationsStatus.COUPONS)
              }}
              onActivateGet={() => onClose()}
            />
          )}
          {registrationsStatus === RegistrationsStatus.ACTIVATE && (
            <RegistrationsActivate
              data={data?.registrationsActivate}
              gs={s}
              registerEmail={fillEmail}
              onActivateBack={() => {
                setRegistrationsStatus(
                  backType === 'main'
                    ? RegistrationsStatus.MAIN
                    : RegistrationsStatus.PASSWORD_LOGIN
                )
                setCurrentStatus(
                  backType === 'main'
                    ? RegistrationsStatus.MAIN
                    : RegistrationsStatus.PASSWORD_LOGIN
                )
                setRegistrationsFillEmail(fillEmail)
              }}
              onActivateGet={() => onClose()}
            />
          )}
        </div>
      </modal>
    </>
  )
}

export default RegistrationsComponent

const CLOSE_ICON_PATH =
  'M16.8525 5.31015C16.4558 4.91337 15.8125 4.91338 15.4157 5.31015L11.1268 9.5991L6.83815 5.31049C6.44138 4.91372 5.79808 4.91372 5.40131 5.31049C5.00454 5.70726 5.00455 6.35055 5.40131 6.74732L9.68993 11.0359L5.40105 15.3248C5.00428 15.7216 5.00428 16.3649 5.40105 16.7616C5.79782 17.1584 6.44111 17.1584 6.83788 16.7616L11.1268 12.4728L15.416 16.762C15.8128 17.1588 16.456 17.1588 16.8528 16.762C17.2496 16.3652 17.2496 15.7219 16.8528 15.3252L12.5636 11.0359L16.8525 6.74698C17.2493 6.35021 17.2493 5.70692 16.8525 5.31015ZM16.921 6.02856C16.921 6.22992 16.8441 6.43127 16.6905 6.5849L14.465 8.8104L12.2395 11.0359L12.2395 11.0359L16.6905 6.58493C16.8441 6.43129 16.921 6.22993 16.921 6.02856ZM5.34966 6.19087C5.37992 6.3354 5.45115 6.47307 5.56336 6.58528L10.014 11.0359L10.014 11.0359L5.56336 6.58524C5.45116 6.47304 5.37992 6.33539 5.34966 6.19087ZM5.34682 16.1923C5.37547 16.3415 5.44757 16.484 5.56309 16.5996C5.87037 16.9068 6.36856 16.9068 6.67583 16.5996L11.1268 12.1486L15.578 16.5999C15.8853 16.9072 16.3835 16.9072 16.6908 16.5999C16.8462 16.4445 16.923 16.2402 16.9212 16.0365C16.923 16.2402 16.8462 16.4445 16.6908 16.5999C16.3835 16.9072 15.8853 16.9072 15.578 16.5999L11.1268 12.1487L6.67583 16.5996C6.36856 16.9069 5.87037 16.9069 5.56309 16.5996C5.44756 16.4841 5.37547 16.3415 5.34682 16.1923Z'

const CloseIcon = ({ onClose = () => {} }) => {
  return (
    <svg
      onClick={onClose}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={CLOSE_ICON_PATH}
        fill="currentColor"
      />
    </svg>
  )
}
